<template>
  <section id="hero" class="hero" ref="hero">
    <div id="vanta-canvas" ref="vantaCanvas"></div>
    <div class="container">
      <h1>
        Analytics on Rails
        <span class="headline-accent">Turn Data Questions into Business Actions. Instantly.</span>
      </h1>
      <p>
        Our <span class="underline">infrastructure platform</span> transforms scattered analytics into 
        <span class="underline">instant advantage</span>, unifying knowledge and connecting the 
        <span class="underline">right insights</span> to the right action seamlessly.
      </p>
      <div class="waitlist-form">
        <div id="waitforit-embed" class="waitlist-placeholder" data-project-id="0b95fd9a-b6e0-4b64-9103-c607b8586cb1">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted, onBeforeUnmount, ref } from 'vue';

export default {
  name: "HeroComponent",
  setup() {
    const hero = ref(null);
    const vantaCanvas = ref(null);
    let vantaEffect = null;

    const initVantaEffect = () => {
      if (!vantaCanvas.value || !window.VANTA) return;

      vantaEffect = window.VANTA.GLOBE({
        el: vantaCanvas.value,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00,
        scale: 0.75,
        scaleMobile: 0.75,
        color: 0x4a7a9d,
        color2: 0xf8c2c9,
        backgroundColor: 0xffffff,
        size: 1.20,
        points: 8.00,
        maxDistance: 20.00,
        spacing: 17.00
      });
    };

    onMounted(() => {
      initVantaEffect();

      // Waitlist script
      const waitlistEmbed = document.getElementById("waitforit-embed");
      const waitlistScript = document.createElement("script");
      waitlistScript.src = "https://www.waitforit.me/waitlistV3.bundle.js";
      waitlistScript.async = true;
      waitlistScript.onload = () => {
        waitlistEmbed.classList.add("fade-in");
      };
      document.head.appendChild(waitlistScript);

      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.type = "text/css";
      link.href = "https://www.waitforit.me/wfiEmbedStyles.css";
      document.head.appendChild(link);
    });

    onBeforeUnmount(() => {
      if (vantaEffect) {
        vantaEffect.destroy();
      }
    });

    return {
      hero,
      vantaCanvas
    };
  }
};
</script>

<style scoped>
.hero {
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  background: #ffffff;
  padding: 60px 20px 20px;
}

#vanta-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.hero .container {
  position: relative;
  z-index: 2;
  text-align: left;
  max-width: 520px;
  margin: 0 0 0 40px;
  padding: 40px 40px;
  background: rgba(255, 255, 255, 0.9);
}

.hero h1 {
  font-size: 56px;
  margin-bottom: 8px;
  margin-top: 0;
  color: #1f2937;
  font-weight: 900;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  line-height: 1.1;
  max-width: 440px;
  white-space: nowrap;
}

.hero p {
  font-size: 18px;
  color: #374151;
  max-width: 440px;
  margin: 0 0 16px;
  line-height: 1.6;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
}

.hero .emphasis {
  font-weight: 700;
  background: linear-gradient(90deg, #4A90E2 0%, #846FE2 50%, #E254A0 100%);
  background-size: 200%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: gradientSweep 3s ease-in-out infinite alternate;
}

.waitlist-form {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0;
  padding-left: 20px;
}

.waitlist-placeholder {
  height: 30px;
  opacity: 0;
  transition: opacity 0.5s ease-in;
  width: 100%;
}

.fade-in {
  opacity: 1;
}

@keyframes gradientSweep {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

@media (min-width: 1200px) {
  .hero {
    padding-left: 40px;
  }

  .hero h1 {
    font-size: 64px;
  }

  .hero p {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  #vanta-canvas {
    display: none;
  }

  .hero {
    justify-content: center;
    padding-left: 0;
    text-align: center;
    padding: 40px 20px;
  }

  .hero .container {
    max-width: 90%;
    margin: 0 auto;
    padding: 24px;
    background: rgba(255, 255, 255, 0.9);
  }

  .headline-accent {
    font-size: 18px;
  }

  .hero h1 {
    font-size: 36px;
    white-space: nowrap;
  }
}

.underline {
  position: relative;
}

.underline::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: -1px;
  left: 50%;
  background: #E254A0;
  transform: translateX(-50%);
  z-index: -1;
  opacity: 0.3;
}

.headline-accent {
  display: block;
  font-size: 20px;
  color: #4a7a9d;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 24px;
  letter-spacing: 0.5px;
}

.hero p .underline {
  position: relative;
  z-index: 1;
}

.hero p .underline::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 30%;
  bottom: -1px;
  left: 50%;
  background: #E254A0;
  transform: translateX(-50%);
  z-index: -1;
  opacity: 0.3;
}
</style>